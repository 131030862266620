import { create } from "zustand";
import { defaultAttachmentMessage } from "../Components/ChatMessageComposer/ChatMessageComposer";
import Communicator from "../Helper/Communicator";
import { validateRecaptchaAction } from "../Helper/Recaptcha";
import {
  WebSocketEvent,
  WebSocketEventData,
} from "../Models/WebSocketEventData";
import { useAppViewStore } from "./AppView";
import { useFormStore } from "./Form";
import { AppMode, useRouterStore } from "./Router";
import { useSessionStore } from "./Session";
import CommunicationManager from "../Helper/CommunicationManager";
import { sanitizeHtmlHelper } from "../Helper/SanitizeHtmlHelper";

export const getRandomMessageId = () => {
  return new Date().toISOString() + Math.random().toString(36).substring(2, 15);
};

export type Ticket = {
  id?: string;
  title?: string;
  bugId?: string;
  latestActionText?: string;
  createdAt?: Date;
  updatedAt?: Date;
  form?: any;
  formData?: any;
  type?: string;
  status?: string;
  screenshotUrl?: string;
  hidden?: boolean;
  hideContent?: boolean;
  conversationClosed?: boolean;
  generatingScreenshot?: boolean;
  sessionNotificationsUnread?: boolean;
  shareToken?: string;
  upvotesCount?: number;
  session?: {
    name: string;
    gleapId: string;
  };
  bot?: {
    active: boolean;
  };
  processingUser?: {
    firstName?: string;
    lastName?: string;
    email?: string;
    id?: string;
    profileImageUrl?: string;
  };
  latestComment?: {
    data: any;
    type: string;
    createdAt: Date;
    updatedAt: Date;
    user: {
      firstName?: string;
      lastName?: string;
      email?: string;
      id?: string;
      profileImageUrl?: string;
    };
    attachments: any[];
    session: {
      name: string;
      gleapId: string;
    };
    fallbackUser: {
      username: string;
    };
  };
};

export type TicketChatMessage = {
  type?: string;
  user?: {
    email?: string;
    firstName?: string;
    lastName?: string;
    id?: string;
    profileImageUrl?: string;
  };
  session?: {
    gleapId?: string;
    email?: string;
    name?: string;
    userId?: string;
    id?: string;
  };
  data?: any;
  attachments?: any[];
  createdAt?: Date;
  updatedAt?: Date;
  id?: string;
  sending?: boolean;
  sessionNotificationsUnread?: boolean;
};

interface TicketState {
  previousTickets: Ticket[];
  ticketFilter: Record<string, any>;
  loadingTickets?: boolean;
  canLoadMore?: boolean;
  currentTicket?: Ticket;
  typingUser: any;
  botWrittingText?: string;
  canSetBotWrittingText?: boolean;
  currentBotAction?: {
    action: any;
    botId: string;
    actionFlowId: string;
    actionId: number;
    lastActionSent: Date;
  };
  uploadingAttachment?: boolean;
  userTypingTimeout: any;
  currentComments: TicketChatMessage[];
  loadingComments?: boolean;
  loadMoreConversations: (arg?: {
    preventLoading?: boolean;
    skip?: number;
  }) => void;
  setCanLoadMore: (canLoadMore: boolean) => void;
  loadConversations: () => void;
  clearUserTyping: () => void;
  getTicketData: () => Promise<any>;
  clearUserTypingTimeout: () => void;
  setUserTyping: (user: any) => void;
  loadCurrentTicket: (shareToken: string) => void;
  clearCurrentTicket: () => void;
  getConversations: (skip: number, limit: number) => Promise<any>;
  setTicketFilter: (filter: Record<string, any>) => void;
  sendComment: (message: string, files: File[]) => void;
  loadComments: (silent?: boolean) => Promise<any>;
  silentRefreshTickets: () => void;
  loadComment: (commentId: string, parentId: string) => void;
  pushComment: (comment: TicketChatMessage) => void;
  uploadFile: (file: File) => Promise<string>;
  isTyping: (typing: boolean) => void;
  createNewConversation: (data: any) => Promise<any>;
  answerBotAction: (data: any) => Promise<any>;
  loadCurrentActionFlow: () => Promise<any>;
  handleWebSocketEvent: (webSocketEventData: WebSocketEventData) => void;
  updateCurrentTicket: (ticketId: string, data: any) => void;
  hasAnswerBotReply: () => boolean;
}

export const useTicketStore = create<TicketState>()((set, get) => ({
  previousTickets: [],
  ticketFilter: {
    status: undefined,
  },
  currentTicket: undefined,
  loadingComments: false,
  currentBotAction: undefined,
  userTypingTimeout: undefined,
  typingUser: undefined,
  currentComments: [],
  uploadingAttachment: false,
  hasAnswerBotReply: () => {
    return get().currentComments.some((comment) => comment.data?.answerBot);
  },
  clearCurrentTicket: () => {
    set({
      currentTicket: undefined,
      currentBotAction: undefined,
      currentComments: [],
      canSetBotWrittingText: false,
      botWrittingText: undefined,
    });
    get().clearUserTyping();
  },
  setCanLoadMore: (canLoadMore: boolean) => {
    set({
      canLoadMore,
    });
  },
  updateCurrentTicket: (ticketId, data) => {
    if (get().currentTicket?.id === ticketId) {
      set((state) => ({
        currentTicket: {
          ...state.currentTicket,
          ...data,
        },
      }));
    }

    var previousTickets = get().previousTickets;
    const index = previousTickets.findIndex(
      (ticket: Ticket) => ticket.id === ticketId
    );

    if (index !== -1) {
      previousTickets[index] = {
        ...previousTickets[index],
        ...data,
      };
    }

    set({
      previousTickets,
    });
  },
  getTicketData: () => {
    return new Promise((resolve, reject) => {
      CommunicationManager.getInstance()
        .sendMessageWithResolver({
          name: "collect-ticket-data",
        })
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          resolve({});
        });
    });
  },
  setTicketFilter: (filter) => {
    set({
      ticketFilter: filter,
    });

    get().loadConversations();
  },
  createNewConversation: (data) => {
    // Notify flow started.
    Communicator.notifyEvent("conversation-started", {
      workflowId: data.botId ?? "",
    });

    return new Promise((resolve, reject) => {
      get()
        .getTicketData()
        .then((ticketData) => {
          const sessionStore = useSessionStore.getState();

          // Open conversation loading.
          useRouterStore.getState().pushPage("conversationloading", {});

          var xhr = new XMLHttpRequest();
          xhr.open("POST", sessionStore.apiUrl + "/bugs/conversation");
          if (sessionStore.session) {
            xhr.setRequestHeader("Api-Token", sessionStore.sdkKey);
            xhr.setRequestHeader(
              "Gleap-Id",
              sessionStore.session.gleapId ?? ""
            );
            xhr.setRequestHeader(
              "Gleap-Hash",
              sessionStore.session.gleapHash ?? ""
            );
          }
          xhr.setRequestHeader(
            "Content-Type",
            "application/json;charset=UTF-8"
          );
          xhr.onerror = function () {
            reject(new Error("Failed creating conversation"));
          };
          xhr.onreadystatechange = function () {
            if (xhr.readyState == 4 && xhr.status == 201 && xhr.responseText) {
              try {
                const data = JSON.parse(xhr.responseText);

                // Open conversations.
                useRouterStore.getState().pushPage("conversation", {
                  shareToken: data.shareToken,
                });

                resolve(data);
              } catch (exp) {
                reject(new Error("Failed creating conversation"));
              }
            }
          };

          xhr.send(
            JSON.stringify({
              botId: data.botId ?? "",
              ...ticketData,
            })
          );
        })
        .catch((error) => {});
    });
  },
  answerBotAction: (data) => {
    return new Promise((resolve, reject) => {
      const sessionStore = useSessionStore.getState();
      const shareToken = get().currentTicket?.shareToken;

      if (!shareToken || shareToken.length === 0) {
        reject(new Error("Failed"));
        return;
      }

      const action = get().currentBotAction?.action;
      if (action?.type === "answerbotflow") {
        Communicator.playPing();

        if (data.action && data.action.length > 0) {
          get().pushComment({
            id: getRandomMessageId(),
            updatedAt: new Date(),
            createdAt: new Date(),
            session: sessionStore.session!,
            type: "BOT_REPLY",
            data: {
              content: data?.message,
              type: "text",
            },
            attachments: data?.attachment ? [data?.attachment] : [],
            sending: false,
          });
        } else {
          if (!get().hasAnswerBotReply()) {
            get().pushComment({
              id: getRandomMessageId(),
              updatedAt: new Date(),
              createdAt: new Date(),
              type: "BOT",
              data: {
                content: action.question,
                type: "text",
              },
              sending: false,
            });
          }

          get().pushComment({
            id: getRandomMessageId(),
            updatedAt: new Date(),
            createdAt: new Date(),
            session: sessionStore.session!,
            type: "BOT_REPLY",
            data: {
              content: data.question,
              type: "text",
            },
            attachments: data?.attachment ? [data?.attachment] : [],
            sending: false,
          });

          get().setUserTyping({
            id: "bot",
            name: "Bot",
            avatar: "",
          });
        }
      } else if (action?.type === "calendly") {
        // Do nothing.
      } else {
        Communicator.playPing();

        // Push local message.
        get().pushComment({
          id: getRandomMessageId(),
          updatedAt: new Date(),
          createdAt: new Date(),
          session: sessionStore.session!,
          type:
            action?.type === "input" || action?.type === "rateconversation"
              ? "BOT_INPUT_REPLY"
              : "BOT_REPLY",
          data: {
            content: data.message,
            rating: data.rating,
            type: action?.type ?? "text",
            question: action.title ?? action.placeholder ?? "",
          },
          sending: true,
        });
      }

      // Clear current bot action
      set({
        canSetBotWrittingText: true,
        currentBotAction: undefined,
      });

      var xhr = new XMLHttpRequest();
      xhr.open(
        "PUT",
        sessionStore.apiUrl + "/bugs/" + shareToken + "/answeractionflow"
      );
      if (sessionStore.session) {
        xhr.setRequestHeader("Api-Token", sessionStore.sdkKey);
        xhr.setRequestHeader("Gleap-Id", sessionStore.session.gleapId ?? "");
        xhr.setRequestHeader(
          "Gleap-Hash",
          sessionStore.session.gleapHash ?? ""
        );
      }
      xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
      xhr.onerror = function () {
        reject(new Error("Failed"));
      };
      xhr.onreadystatechange = function () {
        if (xhr.readyState == 4 && xhr.status == 200) {
          try {
            var data: any = undefined;
            if (xhr.responseText && xhr.responseText.length > 0) {
              data = JSON.parse(xhr.responseText);
            }

            // Update bot with new action.
            const updatedComments = get().currentComments.map((comment) => {
              if (comment.sending) {
                comment.sending = false;
              }
              return comment;
            });

            set({
              currentComments: updatedComments,
            });

            resolve(data);
          } catch (exp) {
            reject(new Error("Failed"));
          }
        }
      };

      xhr.send(JSON.stringify(data));
    });
  },
  loadCurrentActionFlow: () => {
    return new Promise((resolve, reject) => {
      const sessionStore = useSessionStore.getState();
      const shareToken = get().currentTicket?.shareToken;

      if (!shareToken || shareToken.length === 0) {
        reject(new Error("Failed"));
        return;
      }

      var xhr = new XMLHttpRequest();
      xhr.open(
        "GET",
        sessionStore.apiUrl + "/bugs/" + shareToken + "/currentactionflow"
      );
      if (sessionStore.session) {
        xhr.setRequestHeader("Api-Token", sessionStore.sdkKey);
        xhr.setRequestHeader("Gleap-Id", sessionStore.session.gleapId ?? "");
        xhr.setRequestHeader(
          "Gleap-Hash",
          sessionStore.session.gleapHash ?? ""
        );
      }
      xhr.onerror = function () {
        reject(new Error("Failed"));
      };
      xhr.onreadystatechange = function () {
        if (xhr.readyState == 4 && xhr.status == 200 && xhr.responseText) {
          try {
            const data = JSON.parse(xhr.responseText);
            if (data) {
              set({
                currentBotAction: data,
              });
            }
            resolve(data);
          } catch (exp) {
            reject(new Error("Failed"));
          }
        }
      };

      xhr.send();
    });
  },
  uploadFile(file) {
    return new Promise((resolve, reject) => {
      if (file.size / 1024 / 1024 > 10) {
        reject(new Error("File is too big"));
        return;
      }

      const sessionStore = useSessionStore.getState();

      var formData = new FormData();
      formData.append("file", file);

      var xhr = new XMLHttpRequest();
      xhr.open("POST", sessionStore.apiUrl + "/uploads/attachments");
      if (sessionStore.session) {
        xhr.setRequestHeader("Api-Token", sessionStore.sdkKey);
        xhr.setRequestHeader("Gleap-Id", sessionStore.session.gleapId ?? "");
        xhr.setRequestHeader(
          "Gleap-Hash",
          sessionStore.session.gleapHash ?? ""
        );
      }
      xhr.onerror = function () {
        reject(new Error("Upload failed"));
      };
      xhr.onreadystatechange = function () {
        if (xhr.readyState == 4 && xhr.status == 200 && xhr.responseText) {
          try {
            const data = JSON.parse(xhr.responseText);
            if (data.fileUrls && data.fileUrls.length > 0) {
              const dataUrl = data.fileUrls[0];
              resolve(dataUrl);
            } else {
              reject(new Error("Upload failed"));
            }
          } catch (exp) {
            reject(new Error("Upload failed"));
          }
        }
      };

      xhr.send(formData);
    });
  },
  loadCurrentTicket: (shareToken: string) => {
    const oldTicketShareToken = get().currentTicket?.shareToken;
    if (oldTicketShareToken !== shareToken) {
      // Update current ticket & comments.
      const { session, apiUrl, sdkKey } = useSessionStore.getState();

      var xhr = new XMLHttpRequest();
      xhr.open("GET", apiUrl + "/projects/conversations/" + shareToken);
      if (session) {
        xhr.setRequestHeader("Api-Token", sdkKey);
        xhr.setRequestHeader("Gleap-Id", session.gleapId ?? "");
        xhr.setRequestHeader("Gleap-Hash", session.gleapHash ?? "");
      }
      xhr.onerror = function () {};
      xhr.onreadystatechange = function () {
        if (xhr.readyState == 4 && xhr.status == 200 && xhr.responseText) {
          try {
            const data = JSON.parse(xhr.responseText);
            get().clearUserTyping();

            set({
              currentTicket: data,
              currentComments: [],
              currentBotAction: undefined,
              loadingComments: true,
            });

            // Load comments.
            get()
              .loadComments()
              .then(() => {});
          } catch (exp) {}

          try {
            get().loadCurrentActionFlow();
          } catch (exp) {}
        }
      };
      xhr.send();
    }
  },
  loadComments: (silent = false) => {
    const { currentTicket } = get();
    if (!currentTicket) {
      return Promise.reject();
    }

    if (!silent) {
      set({
        loadingComments: true,
      });
    }

    const { apiUrl, session } = useSessionStore.getState();

    return new Promise((resolve, reject) => {
      var xhr = new XMLHttpRequest();
      xhr.open(
        "GET",
        apiUrl + `/bugs/${currentTicket.shareToken}/comments/shared`
      );
      if (session?.gleapId) {
        xhr.setRequestHeader("gleap_id", session?.gleapId);
      }
      if (session?.gleapHash) {
        xhr.setRequestHeader("gleap_hash", session?.gleapHash);
      }
      xhr.onerror = function () {
        reject();
      };
      xhr.onreadystatechange = function () {
        if (xhr.readyState == 4) {
          if (xhr.status == 200 && xhr.responseText) {
            try {
              const data = JSON.parse(xhr.responseText);
              set({
                currentComments: data,
                loadingComments: false,
              });

              return resolve(true);
            } catch (exp) {}
          }

          set({
            loadingComments: false,
          });

          reject();
        }
      };
      xhr.send();
    });
  },
  isTyping: (typing: boolean) => {
    const { currentTicket } = get();
    if (!currentTicket) {
      return;
    }

    const { session, apiUrl } = useSessionStore.getState();

    var xhr = new XMLHttpRequest();
    xhr.open(
      "POST",
      apiUrl + `/bugs/${currentTicket.shareToken}/typing/shared`
    );
    xhr.setRequestHeader("Content-Type", "application/json");
    if (session?.gleapId) {
      xhr.setRequestHeader("gleap_id", session?.gleapId);
    }
    if (session?.gleapHash) {
      xhr.setRequestHeader("gleap_hash", session?.gleapHash);
    }
    xhr.send(
      JSON.stringify({
        typing,
        gleapId: session?.gleapId ?? "",
        gleapHash: session?.gleapHash ?? "",
      })
    );
  },
  loadComment: (commentId: string, parentId: string) => {
    const currentTicket = get().currentTicket;
    if (!currentTicket) {
      return;
    }

    const { apiUrl, session } = useSessionStore.getState();

    var xhr = new XMLHttpRequest();
    xhr.open(
      "GET",
      apiUrl +
        `/bugs/${currentTicket.shareToken}/comments/shared/${commentId}?vs=${document.visibilityState}`
    );
    if (session?.gleapId) {
      xhr.setRequestHeader("gleap_id", session?.gleapId);
    }
    if (session?.gleapHash) {
      xhr.setRequestHeader("gleap_hash", session?.gleapHash);
    }
    xhr.onerror = function () {};
    xhr.onreadystatechange = function () {
      if (xhr.readyState == 4 && xhr.status == 200 && xhr.responseText) {
        try {
          const data = JSON.parse(xhr.responseText);

          var newComments = get().currentComments.filter((comment) => {
            return !comment.sending;
          });

          const currentTicket = get().currentTicket;
          if (currentTicket?.id === parentId) {
            // Check if comment already exists.
            const index = newComments.findIndex(
              (comment) => comment.id === data.id
            );
            if (index === -1) {
              newComments.push(data);
            }

            set({
              currentComments: newComments,
            });
          }
        } catch (exp) {}
      }
    };
    xhr.send();
  },
  pushComment: (comment: TicketChatMessage) => {
    set({
      currentComments: [...get().currentComments, comment],
    });
  },
  sendComment: async (message: string, attachments: File[]) => {
    const { session, apiUrl } = useSessionStore.getState();
    const { currentTicket } = get();
    if (!currentTicket || !session) {
      return;
    }

    Communicator.playPing();

    set({
      uploadingAttachment: true,
    });

    get().pushComment({
      id: getRandomMessageId(),
      updatedAt: new Date(),
      createdAt: new Date(),
      session: session,
      type: "SHARED_COMMENT",
      data: {
        content: sanitizeHtmlHelper(message),
      },
      sending: true,
    });

    try {
      var attachmentsUrls: any[] = [];
      for (const file of attachments) {
        try {
          const fileUrl = await get().uploadFile(file);
          attachmentsUrls.push({
            url: fileUrl,
            name: file.name,
            type: file.type,
          });
        } catch (exp) {}
      }

      if (attachmentsUrls.length <= 0 && message === defaultAttachmentMessage) {
        try {
          set({
            currentComments: get().currentComments.map((comment) => {
              if (comment.sending) {
                comment.sending = false;
                comment.data.content = "❌ File upload failed. File too big.";
              }
              return comment;
            }),
            uploadingAttachment: false,
          });
        } catch (exp) {}

        return;
      }

      const token = await validateRecaptchaAction("sendchatmessage");

      var xhr = new XMLHttpRequest();
      xhr.open(
        "POST",
        apiUrl + `/bugs/${currentTicket.shareToken}/comments/shared`
      );
      xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
      xhr.onerror = function () {};
      xhr.onreadystatechange = function () {
        if (xhr.readyState == 4 && xhr.status == 201) {
          try {
            set({
              currentComments: get().currentComments.map((comment) => {
                if (comment.sending) {
                  comment.sending = false;
                  comment.attachments = attachmentsUrls;
                }
                return comment;
              }),
              uploadingAttachment: false,
            });
          } catch (exp) {}
        } else {
          set({
            uploadingAttachment: false,
          });
        }
      };
      xhr.send(
        JSON.stringify({
          comment: message,
          gleapId: session.gleapId ?? "",
          gleapHash: session.gleapHash ?? "",
          attachments: attachmentsUrls,
          token,
        })
      );
    } catch (exp) {}
  },
  loadConversations: () => {
    set({
      previousTickets: [],
      loadingTickets: false,
      canLoadMore: true,
    });

    get().loadMoreConversations();
  },
  silentRefreshTickets: () => {
    get()
      .getConversations(0, 25)
      .then((data) => {
        const newTickets = [...data, ...get().previousTickets].filter(
          (v, i, a) => a.findIndex((v2) => v2.id === v.id) === i
        );

        set({
          previousTickets: newTickets,
        });
      })
      .catch(() => {});
  },
  getConversations: (skip = 0, limit = 25) => {
    const { session, apiUrl, sdkKey } = useSessionStore.getState();

    let query = `limit=${limit}&skip=${skip}`;

    if (get().ticketFilter.status) {
      if (get().ticketFilter.status === "OPEN") {
        query += `&status!=CLOSED,DONE`;
      } else {
        query += `&status=${get().ticketFilter.status}`;
      }
    }

    return new Promise((resolve, reject) => {
      var xhr = new XMLHttpRequest();
      xhr.open("GET", apiUrl + `/projects/conversations?${query}`);
      if (session) {
        xhr.setRequestHeader("Api-Token", sdkKey);
        xhr.setRequestHeader("Gleap-Id", session.gleapId ?? "");
        xhr.setRequestHeader("Gleap-Hash", session.gleapHash ?? "");
      }
      xhr.onerror = function () {
        return reject();
      };
      xhr.onreadystatechange = function () {
        if (xhr.readyState == 4 && xhr.status == 200 && xhr.responseText) {
          try {
            const data = JSON.parse(xhr.responseText);
            return resolve(data);
          } catch (exp) {
            return reject(exp);
          }
        }
      };
      xhr.send();
    });
  },
  loadMoreConversations: (args) => {
    if (get().loadingTickets || !get().canLoadMore) {
      return;
    }

    if (args?.preventLoading) {
      set({
        loadingTickets: true,
      });
    }

    const skip = args?.skip ?? get().previousTickets.length;

    get()
      .getConversations(skip, 25)
      .then((data) => {
        var newTickets: any[] = [];
        if (data && data.length > 0) {
          newTickets = [...get().previousTickets];
          newTickets = newTickets.filter(function (el) {
            return data.indexOf(el) < 0;
          });
          newTickets = [...newTickets, ...data];
        } else {
          newTickets = [...get().previousTickets];
        }

        // Remove duplicates.
        newTickets = newTickets.filter(
          (v, i, a) => a.findIndex((v2) => v2.id === v.id) === i
        );

        set({
          previousTickets: newTickets,
          loadingTickets: false,
          canLoadMore: data.length > 0,
        });
      })
      .catch(() => {
        set({
          loadingTickets: false,
          canLoadMore: false,
        });
      });
  },
  clearUserTyping: () => {
    get().clearUserTypingTimeout();
    set({
      typingUser: undefined,
    });
  },
  clearUserTypingTimeout: () => {
    const timeout = get().userTypingTimeout;
    if (timeout) {
      clearTimeout(timeout);
    }
  },
  setUserTyping: (user: any) => {
    get().clearUserTypingTimeout();
    set({
      typingUser: user,
      userTypingTimeout: setTimeout(() => {
        set({
          userTypingTimeout: undefined,
          typingUser: undefined,
        });
      }, 20000),
    });
  },
  handleWebSocketEvent: (webSocketEventData: WebSocketEventData) => {
    switch (webSocketEventData.action) {
      case WebSocketEvent.MESSAGE_CREATED:
        Communicator.playPing();

        if (get().currentTicket?.id !== webSocketEventData.parentID) {
          return;
        }

        set({
          botWrittingText: undefined,
          canSetBotWrittingText: false,
        });
        get().clearUserTyping();

        // Load new comment.
        get().loadComment(
          webSocketEventData.actionID,
          webSocketEventData.parentID
        );
        break;

      case WebSocketEvent.BUG_TYPING:
        if (
          get().currentTicket?.id === webSocketEventData.actionData?.ticketId
        ) {
          if (
            webSocketEventData.actionData?.typing &&
            webSocketEventData.actionData?.user
          ) {
            get().setUserTyping(webSocketEventData.actionData?.user);
          } else {
            get().clearUserTyping();
          }
        }
        break;

      case WebSocketEvent.BOT_WRITTING:
        const conversationId = webSocketEventData?.actionData?.conversationId;
        if (
          get().currentTicket?.id === conversationId &&
          get().canSetBotWrittingText
        ) {
          const botWritting = webSocketEventData?.actionData?.message;
          set({
            botWrittingText: botWritting,
          });
        }
        break;

      case WebSocketEvent.BOT_TOOL:
        const toolName = webSocketEventData?.actionData?.name;
        if (toolName && toolName.length > 0) {
          // Send to communicator.
          CommunicationManager.getInstance().sendMessage({
            name: "tool-execution",
            data: {
              name: toolName,
              params: webSocketEventData?.actionData?.params ?? {},
            },
          });
        }
        break;

      case WebSocketEvent.CUSTOMACTION_RUN:
        Communicator.runCustomAction(
          webSocketEventData?.actionData?.actionName
        );

        if (webSocketEventData?.actionData?.closeWidget ?? true) {
          Communicator.closeWidget();
        }
        break;

      case WebSocketEvent.BUG_CREATED:
        // Reload conversations.
        get().silentRefreshTickets();
        break;

      case WebSocketEvent.BUG_UPDATED:
        if (webSocketEventData?.actionData?.update) {
          if (
            typeof webSocketEventData?.actionData?.update?.bot?.active ===
              "boolean" &&
            webSocketEventData?.actionData?.update?.bot?.active === false
          ) {
            // Reset current action.
            set({
              currentBotAction: undefined,
            });
          }

          get().updateCurrentTicket(
            webSocketEventData?.actionID,
            webSocketEventData?.actionData?.update
          );
        }
        break;

      case WebSocketEvent.BOT_UPDATECURRENTACTION:
        setTimeout(() => {
          set({
            currentBotAction: webSocketEventData?.actionData,
            typingUser: undefined,
          });
        }, 2000);
        break;

      case WebSocketEvent.BOT_OPENLINK:
        if (webSocketEventData?.actionData?.href) {
          Communicator.openURL(
            webSocketEventData?.actionData?.href,
            webSocketEventData?.actionData?.newTab
          );
        }
        break;

      case WebSocketEvent.BOT_STARTFEEDBACKFLOW:
        const flow = webSocketEventData?.actionData?.flow;
        if (webSocketEventData?.actionData?.flow) {
          useFormStore.setState({
            feedbackFlow: flow,
            isSurveyFlow: false,
          });
          useRouterStore.getState().setPage(
            "flow",
            {
              flowId: flow,
            },
            AppMode.WIDGET
          );
          useAppViewStore.getState().reset();
        }
        break;

      default:
        break;
    }
  },
}));
